import { Component, OnInit, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent{

  constructor(
    private router: Router,

  ) { }

goToLogin(){
  this.router.navigate(['/login']);
}

}
