<html><div class="mazaya-cart">
  <div class="mazaya-cart-btn" (click)="getCartItems()" (clickOutside)="updateCart()">
    <div class="counter" *ngIf="cartCount>0">{{cartCount}}</div>
  </div>

  <div class="mazaya-cart" *ngIf="notificationModal">
    <div class="sidebar">
      <div class="check-header-card">
        <h4>My Cart</h4>
        <img (click)="notificationModal = false" class="arrow-card" src="../../../assets/images/icons/close-icon.svg"
          alt="" srcset="">
      </div>

      <div class="container-cart" *ngIf="cartItemData!=null">
        <div class="top-cart">
       
          <div class="sidebar-card" *ngFor="let cartItem of cartItemData.subCategory; let i=index; trackBy: trackByFn">
         
            <div class="side-overlay">
              <img class="side-user"[src]="cartIcon.src"
                alt="user-sidebar">
            </div>
            <div class="card-content">
              <div class="price-validity">
                Valid Until 31st Dec 2024
              </div>
              <div class="check-header">

                <div class="title">{{cartItem.name}}</div>
                <div class="price-sidebar">{{cartItem.currency}}<sub class="suber-text">{{cartItem.amount}}</sub>
                </div>
              </div>
             
              <div class="check-header">
                
                <div class="para-sidebar">
                  {{cartItem.description}}
                </div>

                <div class="spinner-section-second"
                  *ngIf="cartItem.name!='FAMILY MEMBERSHIP' && cartItem.name!='SINGLE MEMBERSHIP'">
                  <div class="img-user"><img src="../../../assets/images/mazayaPlus/user-filled.svg" alt="user"></div>
                  <div class="img-spinner" style="cursor: pointer;" (click)="onDecrement(i)"><img
                      src="../../../assets/images/mazayaPlus/minus.svg" alt="minu"></div>
                  <div>{{cartItem.count}}</div>
                  <div class="img-spinner-add" style="cursor: pointer;" (click)="onIncrement(i)"><img
                      src="../../../assets/images/mazayaPlus/add.svg" alt="plus"></div>
                  <div class="delete-section" style="cursor: pointer;" (click)="deleteSubCategory(i)">
                    <img class="check-delete" src="../../../assets/images/mazayaPlus/iconDelete.svg"
                      alt="side-delete">
                  </div>
                </div>
                <div class="delete-section"
                  *ngIf="cartItem.name=='FAMILY MEMBERSHIP' ||  cartItem.name=='SINGLE MEMBERSHIP'"
                  (click)="deleteCart()" style="cursor: pointer;">
                  <img class="check-delete" src="../../../assets/images/mazayaPlus/iconDelete.svg" alt="side-delete">
                </div>
              </div>

            </div>
          </div>


        </div>
        <div class="bottom-cart" *ngIf="cartItemData.subCategory.length>0">
          <div class="payment-sidebar">
            <div class="payment-text">
              <div>Total</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(totalAmount)}}</div>
            </div>
            <div class="payment-text">
              <div>Vat</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(totalVat)}}</div>
            </div>
            <div class="divider-checkout"></div>
            <div class="payment-total">
              <div>Grand Total</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(grandTotalAmount)}}</div>
            </div>

          </div>
          <!-- <button class="price-btn" (click)="checkout()">Checkout</button> -->
          <button class="price-btn" (click)="openModal()"  >Checkout</button>
        </div>
      </div>
      <div class="container-cart" *ngIf="cartItemData==null">
        <div class="empty-container">
          <div>
            <img src="../../../assets/images/mazayaPlus/empty.svg" alt="">
            <div>
              <div class="empty-bold">Your cart is feeling a bit light</div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal-container" *ngIf="isOpen">
  <div class="modal modal-width-m">
    <div class="modal-header">
     
      <h2>Confirmation</h2>
      <p class="warning-text">
        <b> Membership is valid until 31st Dec 2024 </b> 
        
      </p>
    </div>
    <div class="modal-body">
     
      <form [formGroup]="confirmationForm">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="confirmCheck" formControlName="checkConfirm">
          <label class="form-check-label" for="confirmCheck">
            I understand and agree
          </label>
        </div>
        <div class="error" *ngIf="confirmationForm.get('checkConfirm').invalid && confirmationForm.get('checkConfirm').touched">
          Please confirm to proceed
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn-confirm" (click)="confirmCheckout()">Proceed</button>
      <button type="button" class="btn-cancel" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</div>

</html>