<div class="collections-home-container">
    <div class="header">
        <div class="title">Collections</div>
        <div class="sub-title">Collections tailored for you</div>
    </div>
    <div class="content">
        <div class="carousel" #carouselcollection>
            <div class="carousel_page" *ngFor="let collectionPage of collectionsPage; let i = index">
                <div class="collection-cards">
                    <div class="collection-card" *ngFor="let collection of collectionPage">
                        <img class="img-cover" (load)="bannerIsReady(i)"
                            [src]="loadImg(i, collection.imageUrls.large )">
                        <div class="data">
                            <div class="title" [clamp]="1">
                                {{ collection.title }}
                            </div>
                            <div class="description" [clamp]="2">
                                {{ collection.description }}
                            </div>
                            <div class="footer">
                                <button class="btn-blue" *ngIf="collection.id!=158" (click)="clickBanner(collection.id)"> View Deal</button>
                                <button class="btn"  *ngIf="collection.id==158" (click)="clickBanner(collection.id)">View Benefits</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-pagination">
            <div class="page" *ngFor="let page of numberToArray(carouselPageNumber); let i = index"
                [class.page-active]="corouselPageCurent == i + 1" (click)="clickPage(i + 1)">
            </div>
        </div>
    </div>
</div>