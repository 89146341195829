import { Subscription } from 'rxjs';
import { AdnocUsersService } from './../../shared/services/adnoc-users.service';
import { ChangeSearch, DeleteHistory, ResetPage } from './../../state/page-history-state-model';
import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Router, Event } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState, Logout, PageHistoryState } from 'src/app/state';
import { LoginService } from 'src/app/core/services';
import { LocalStorage } from 'src/app/core/security/local-storage';
import { Auth, AuthSecurity } from 'src/app/core/security/auth';
import { FiltersService } from 'src/app/shared/services/filters.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styles: []
})

export class NavigationComponent implements OnInit, OnDestroy {

  @ViewChild('navSub', { read: ElementRef }) public navSubSmallScreen;

  // Search
  searchTextPlaceholder: string = "";
  searchText: string = "";

  // Modals
  notificationModal: boolean;
  dropDownUserModal: boolean;
  dropDownNavModal: boolean;
  selectedDropDown: string;

  // Profile image
  profilePicture: string = null;
  clientsPhotoChanged: Subscription;

  // Responsive navigation
  timerNavSmall: any;
  activeNav: string;
  isMazayaEligible: boolean = false;
  isMazayaAdmin: boolean = false;
  membercardcount: boolean = false;
  // signalR
  assignedOffersCount: number = 0;
  blockedOffersCount: number = 0;

  role: string;

  constructor(
    private router: Router,
    private store$: Store,
    private adnocUserService: AdnocUsersService,
    private loginService: LoginService,
    private _filterService: FiltersService,
  ) {
    this.router.events.subscribe((event: Event) => {
      this.changeActiveNav(this.router.url.split("/")[1]);
    });
  }

  ngOnInit() {
    this.loadAuth();
    this.initSearch();
    this.initUserProfileImage();
    this.subscribeFor_usersPhotoChanged();
    this.checkMazayaPlus();
  }

  loadAuth() {
    this.store$.select(AuthState.roles).subscribe((roles) => {
      this.role = roles[0];
    });
  }

  subscribeFor_usersPhotoChanged() {
    this.clientsPhotoChanged = this.adnocUserService.clientsPhotoChanged.subscribe(() => {
      this.initUserProfileImage();
    });
  }

  initUserProfileImage() {
    if (!this.store$.selectSnapshot(AuthState.userId)) {
      return;
    }
    this.adnocUserService.getAdnocUsersProfilePicture().subscribe((res) => {
      if (res) {
        this.profilePicture = res.toLocaleUpperCase(); // to upper and to string
      } else {
        this.profilePicture = null;
      }
    });
  }

  userProfileImageState($event) {
    // if ($event.reason == "loading-failed") {
    //   setTimeout(() => {
    //     this.profilePicture = "111-111-111-111";
    //     this.initUserProfileImage();
    //   }, 500);
    // }
  }

  ngOnDestroy() {
    this.clientsPhotoChanged.unsubscribe();
  }

  initSearch() {
    this.store$.select(PageHistoryState).subscribe((res) => {
      this.searchTextPlaceholder = res.searchPlaceholder;
      this.searchText = res.query.filter.keyword;
    });
  }

  changeActiveNav(activeNav) {

    this.activeNav = activeNav;
  }

  routeToAllSuggestion() {
    this.router.navigateByUrl('suggestions/all')
  }
  search() {
    this.store$.dispatch(new ChangeSearch(this.searchText));
  }

  searchReset() {
    this.searchText = "";
    this.search();
  }

  logOut() {
    this.loginService.logout().subscribe(() => {
      localStorage.clear();
      sessionStorage.clear();
      this.dropDownUserModal = !this.dropDownUserModal;
      this.store$.dispatch(new DeleteHistory());
      this.store$.dispatch(new ResetPage());
      this.store$.dispatch(new Logout());
    })

  }

  reset() {
    this.store$.dispatch(new ResetPage());
  }

  // Navigations (responsive)
  moveToRightNavSub(): void {
    this.timerNavSmall = setInterval(() => {
      this.navSubSmallScreen.nativeElement.scrollLeft += 15;
    }, 50);
  }

  moveToLeftNavSub(): void {
    this.timerNavSmall = setInterval(() => {
      this.navSubSmallScreen.nativeElement.scrollLeft -= 15;
    }, 50);
  }

  stopMoveNavSub(): void {
    clearInterval(this.timerNavSmall);
  }
  checkMazayaPlus() {

    this.store$.select(AuthState.token).subscribe((token) => {
      if (token) {
        this._filterService.getUserInfo().subscribe((res) => {

          this.isMazayaAdmin=res.mazayauserdetails.isAdmin
          this.isMazayaEligible= res.isMazayaEligible
          if(res.mazayauserdetails.membercardcount!=0){
            this.membercardcount=true;
          }
        
          })
      }
    })


//     this.store$.select(AuthState.isMazayaAdmins).subscribe((value) => {
//       this.isMazayaAdmin = value;
//     });
//     this.store$.select(AuthState.isMazayaEligible).subscribe((value) => {

//       this.isMazayaPlus= value;
//     });

// console.log("#####################",this.isMazayaAdmin, this.isMazayaPlus)

  }
}
