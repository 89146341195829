import { OfferPostModel } from './../../../../../shared/models/offers/offer-post-model';
import { OffersService } from 'src/app/shared/services/offers.service';
import { BannersService } from 'src/app/shared/services/banners.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { OfferModel } from 'src/app/shared/models';
import { CategoryModel } from 'src/app/shared/models';
import { CollectionModel } from 'src/app/shared/models/collections/collection-model';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { Router } from '@angular/router';
import { CollectionsService } from 'src/app/shared/services/collections.service';
import { BannerModel } from 'src/app/shared/models/banners/banner.model';
import { merge, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/state';
import * as moment from 'moment';

@Component({
  selector: 'app-home-buyer',
  templateUrl: './home-buyer.component.html',
  styles: []
})
export class HomeBuyerComponent implements OnInit {
  currentYear = moment().year();
  categories: CategoryModel[];
  latestOffers: OfferPostModel[];
  banners: BannerModel[];
  collections: CollectionModel[];
  collectionsPage: CollectionModel[][]
  private offersLimit = 8;
  role: string;


  // Optimization
  imageLoadOffset$: any;
  startScroll: boolean = false;
  showBanner: boolean = false;
  showCategories: boolean = false;
  showCollections: boolean = false;
  showOffers: boolean = false;
  showRoadshow: boolean = false;

  constructor(
    private _categoriesService: CategoriesService,
    private _collectionsService: CollectionsService,
    private _bannersService: BannersService,
    private _offersService: OffersService,
    private _router: Router,
    private _store: Store
  ) {
    this.imageLoadOffset$ = merge(of(300), of(100000));
  }

  ngOnInit(): void {
    window.onbeforeunload = function () { window.scrollTo(0, 0); }
    this.loadBanners();
    this.loadCategories();
    this.loadAuth();
  }

  loadAuth() {
    this._store.select(AuthState.roles).subscribe((roles) => {
      this.role = roles[0];
    });
  }

  // Data
  loadBanners(): void {
    this._bannersService.getBanners()
      .subscribe(res => {
        this.banners = res;
        if (this.banners.length == 0) {
          this.showCategories = true;
          this.loadCollections();
          this.loadLatestOffers();
        }
      });
  }

  loadCategories(): void {
    this._categoriesService.getCategories()
      .subscribe(res => {
        this.categories = res;
      });
  }

  loadLatestOffers(): void {
    this._offersService.getLatestOffers(this.offersLimit)
      .subscribe(res => {
        this.latestOffers = res;
      });
  }

  loadCollections(): void {
    this._collectionsService.getCollections()
      .subscribe(res => {
        this.collections = res;
        if (this.collections.length == 0) {
          this.showOffers = true;
        }
      });
  }

  // Actions
  onCategoryClick(id: number) {
    this._router.navigate([`offers/categories/${id}`]);
  }

  onBannerClick(id: number) {
    this._router.navigate([`offers/${id}`]);
  }

  onCollectionClick(id: number) {
    this._router.navigate([`offers/collections/${id}`]);
  }

  onOfferClick(id: number) {
    this._bannersService.logClickOnBanner(id).subscribe();
    this._router.navigate([`offers/${id}`]);
  }

  onRoadshowClick() {
    if (this.role == "Admin" || this.role == "AdnocCoordinator") {
      this._router.navigate([`roadshows-administration/planning`]);
    } else {
      this._router.navigate([`roadshows/all`]);
    }
  }

  // Other

  array(n: number): number[] {
    return [...Array(n).keys()];
  }
  /* 
  Kolekcije i offeri se ocitavaju tek nakon sto je zapocet (scroll) strane
  */
  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if (!this.startScroll) {
      this.startScroll = true;
      this.loadCollections();
      this.loadLatestOffers();
    }
  }
}
