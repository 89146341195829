import { Component, EventEmitter, NgZone, Output, } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CartModel } from 'src/app/shared/models/mazaya-plus/cart-model';
import { Subcategory } from 'src/app/shared/models/mazaya-plus/subcategory-model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-cart',
  templateUrl: './add-cart.component.html',
  styleUrls: ['./add-cart.component.scss'],
})
export class AddCartComponent {

  notificationModal: boolean=false;

  @Output() onClose = new EventEmitter();

  cartItemData: CartModel = null;
  totalAmount: number = 0;
  totalVat: number = 0;
  grandTotalAmount: number = 0;
  cartCount: Number = 0;
  cartIcon: HTMLImageElement = new Image();
  isOpen: boolean = false;
  confirmationForm: FormGroup;
  confirmDeleteChecked = false;
  
  constructor(
    private _router: Router,
    private zone: NgZone,
    private _notificationService: NotificationsService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.cartIcon.src="../../../assets/images/mazayaPlus/iconCarrier-single.svg"
    setInterval(() => {
      this.zone.run(() => {
        const localStorageValue = localStorage.getItem('cart'); 
  
        if (localStorageValue) {
          const data = JSON.parse(localStorageValue);
          this.cartCount=data.subCategory.length;
         
        }else{
          this.cartCount=0;
        }
      });
    }, 1000);
    this.confirmationForm = this.fb.group({
      checkConfirm: [false, Validators.requiredTrue]
    });
    this.amountCalculation();
  }

  trackByFn(index: number, item: any): any {
    return item.id; // Replace with a unique identifier for each item in your case
  }
  getCartItems() {

    const storedData = localStorage.getItem('cart');
    if (storedData) {
      const data = JSON.parse(storedData);
      this.cartItemData = data

      this.amountCalculation();
    }

     if (this.notificationModal == false)
      this.notificationModal = true
  }

  deleteSubCategory(i: number) {

    this.cartItemData.subCategory.splice(i, 1);
    if (this.cartItemData.subCategory.length == 0) {
   
      localStorage.removeItem('cart');
      this._router.navigate(["/mazaya-plus"]);
      this.notificationModal=false;
      this.onClose.emit();

    } else {
      localStorage.setItem('cart', JSON.stringify(this.cartItemData));
      this.amountCalculation();
    }

  }
  amountCalculation() {
    // if (this.cartItemData?.subCategory.length == 2) {
    //   debugger
    //   this.totalVat = this.cartItemData?.subCategory[0]?.vat + (this.cartItemData?.subCategory[1]?.vat * this.cartItemData?.subCategory[1]?.count)
    //   this.totalAmount = this.cartItemData?.subCategory[0]?.amount + (this.cartItemData?.subCategory[1]?.amount * this.cartItemData?.subCategory[1]?.count)
    //   this.grandTotalAmount = this.totalAmount + this.totalVat
    // } else {
    //   debugger
    //   this.totalVat = this.cartItemData?.subCategory[0]?.vat * this.cartItemData?.subCategory[0]?.count
    //   this.totalAmount = this.cartItemData?.subCategory[0]?.amount * this.cartItemData?.subCategory[0]?.count
    //   this.grandTotalAmount = this.totalAmount + this.totalVat
    // }
    this.totalVat = 0;
    this.totalAmount = 0;

    for (let i = 0; i < this.cartItemData?.subCategory?.length; i++) {
      this.totalVat=this.totalVat+(this.cartItemData?.subCategory[i]?.vat) * this.cartItemData?.subCategory[i]?.count
      this.totalAmount=this.totalAmount+(this.cartItemData?.subCategory[i]?.amount) * this.cartItemData?.subCategory[i]?.count
     
    }
    this.grandTotalAmount=this.totalAmount+ this.totalVat

  }

  onIncrement(i:number) {
    if (this.cartItemData.subCategory[i].count != this.cartItemData.subCategory[i].totalcount) {
    this.cartItemData.subCategory[i].count++
    localStorage.setItem('cart', JSON.stringify(this.cartItemData));
    this.amountCalculation()
    }else{
      this._notificationService.error(
        "",
        "Maximum count reached"
      );
    }
  }

  onDecrement(i:number) {
    if (this.cartItemData.subCategory[i].count !== 0) {
      this.cartItemData.subCategory[i].count--
      localStorage.setItem('cart', JSON.stringify(this.cartItemData));
      this.amountCalculation()
      if(this.cartItemData.subCategory[i].count==0)
      this.deleteSubCategory(i);
    }
}



  onCloseButton() {
    this.notificationModal = false;
    //  this.onClose.emit();
  }
  // checkout() {
  //   this.notificationModal = !this.notificationModal;
  //   this.handleLocalStorage()
  //   this._router.navigate(["/mazaya-plus/check-out"]);
  // }
 

  confirmCheckout() {
    if (this.confirmationForm.valid) {
      this.isOpen = false;
      this.notificationModal = !this.notificationModal;
    this.handleLocalStorage()
      this._router.navigate(["/mazaya-plus/check-out"]);
    } else {
      this.confirmationForm.markAllAsTouched();
    }
  }

  onCancel() {
    this.isOpen = false;
  }
  openModal() {
    this.isOpen = true; 
  }
  addBreakLine(name: string): string {
    return name.replace(/ /g, '<br/>')
  }
  deleteCart() {
    this.cartItemData = null
    localStorage.removeItem('cart');
    this.onClose.emit();
    this._router.navigate(["/mazaya-plus"]);
    this.notificationModal=false;
  }

  handleLocalStorage() {
    localStorage.setItem('cart', null);
    localStorage.setItem('cart', JSON.stringify(this.cartItemData));
  }
  updateCart(){
    const storedData = localStorage.getItem('cart');
    if (storedData) {
      const data = JSON.parse(storedData);
      this.cartItemData = data

      this.amountCalculation();
    }else{
      
      this.cartItemData = null
    }
  }
  roundAmount(amount:number):number{
    return parseFloat(amount.toFixed(2))
  }
}
