import { State, Selector, Action, StateContext } from '@ngxs/store';
import { AuthStateModel, Login, Logout, LoginSuccess, LoginError, TryRefresh, LoginWithToken, LogInfo, } from './auth-state-model';
import { Injectable } from '@angular/core';
import { tap, catchError, filter } from 'rxjs/operators';
import { LoginService } from '../core/services';
import { of } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';
import * as jwt_decode from 'jwt-decode';
import { get } from 'lodash';
import { LogoutTest } from './data-state-model';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { NotificationsService } from '../shared/services/notifications.service';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    username: null,
    email: null,
    refresh: null,
    roles: null,
    refreshTime: null,
    userId: null,
    companyStatus: null

  }
})


@Injectable()
export class AuthState {

  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  @Selector()
  static roles(state: AuthStateModel): Array<string> {
    return state.roles || [];
  }

  @Selector()
  static userId(state: AuthStateModel): string | null {
    return state.userId;
  }
  constructor(private loginService: LoginService, private notificationService: NotificationsService) { }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.loginService.login(action.payload)
      .pipe(
        catchError(error => {
          this.notificationService.error("",'Invalid username or pasword');
          ctx.dispatch(new LoginError(error));
          return of(null);
        }),
        filter(model => !!model),
        tap((model: { token: string, refresh; }) => {


          const customHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${model.token}`,
          });

          const decoded = jwt_decode(model.token);
          const token = model.token || null;
          const refresh = model.refresh || null;
          const username = get(decoded, 'sub', null);
          const email = get(decoded, 'email', null);
          const roles = get(decoded, 'roles', []);
          const userId = get(decoded, 'userId', null);
          const companyStatus = get(decoded, 'companyStatus', null);

          if (roles[0] === 'Buyer' || roles[0] === 'RoadshowFocalPoint' || roles[0] === 'Reviewer'
            || roles[0] === 'AdnocCoordinator') {
            ctx.patchState({
              token: token,
              username: username,
              email: email,
              refresh: refresh,
              roles: roles,
              userId: userId,
              companyStatus: companyStatus

            });
            ctx.dispatch(new LoginSuccess());
            }
          else {
            this.notificationService.error("", 'Invalid username or pasword');
            return
          }
        })
      );
  }

  @Action(LoginWithToken)
  loginWithToken(ctx: StateContext<AuthStateModel>, action: LoginWithToken) {
    const decoded = jwt_decode(action.payload.token);
    const username = get(decoded, 'sub', null);
    const email = get(decoded, 'email', null);
    const roles = get(decoded, 'roles', []);
    const userId = get(decoded, 'userId', null);
    const companyStatus = get(decoded, 'companyStatus', null);
    ctx.setState({
      token: action.payload.token,
      username: username,
      email: email,
      refresh: action.payload.refresh,
      roles: roles,
      refreshTime: null,
      userId: userId,
      companyStatus: companyStatus,
    });

    ctx.dispatch(new LoginSuccess());
  }

  @Action(LoginSuccess)
  loginSuccess(ctx: StateContext<AuthStateModel>, action: LoginSuccess) {
    if (ctx.getState().companyStatus == "MissingTradeLicense") {
      ctx.dispatch(new Navigate(['registration/company-trade-license-update']));
    }

    else if (ctx.getState().roles.length == 0) {
      ctx.dispatch(new Navigate(['registration/custom-registration-info']));
    }

    else if (ctx.getState().companyStatus == "PendingApproval") {
      ctx.dispatch(new Navigate(['registration/custom-registration-info']));
    }
    else {
      
      ctx.dispatch(new Navigate(['/']));
    }

  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>, action: Logout) {
    return of(true)
      .pipe(
        tap(() => {
          // this.logoutFromMicrosoftService(ctx.getState().token);
          // this.reset(ctx);
          this.reset(ctx);
          localStorage.clear(); 
          ctx.dispatch(new Navigate(['/login']));
        })
      );
  }

  @Action(LogInfo)
  loginfo(ctx: StateContext<AuthStateModel>, action: LogInfo) {
    return of(true)
      .pipe(
        tap(() => {
          this.reset(ctx);
          ctx.dispatch(new Navigate(['registration/custom-registration-info']));
        })
      );
  }

  @Action(LogoutTest)
  logTest(ctx: StateContext<AuthStateModel>, action: LogInfo) {
    return of(true)
      .pipe(
        tap(() => {
          this.reset(ctx);
          ctx.dispatch(new Navigate(['/login/skipb2c']));
        })
      );
  }

  @Action(LoginError)
  loginError(ctx: StateContext<AuthStateModel>, action: LoginError) {
    return of(true)
      .pipe(
        tap(() => {
          this.reset(ctx);
        })
      );
  }

  @Action(TryRefresh)
  refresh(ctx: StateContext<AuthStateModel>, action: TryRefresh) {
    const refreshToken = ctx.getState().refresh;
    const token = ctx.getState().token;
    
    if (!refreshToken || !token) {
      this.reset(ctx);
      localStorage.clear(); 
      ctx.dispatch(new Navigate(['/login']));
    }

    return this.loginService.refresh(refreshToken)
      .pipe(
        tap(result => {
          if (result.token && result.refresh) {
            
            ctx.patchState({
              token: result.token,
              refresh: result.refresh
            });
          }
        }),
        catchError((error, caught) => {
          this.reset(ctx);
          ctx.dispatch(new Navigate(['/login']));
          return of('');
        })
      );
  }

  reset(ctx: StateContext<AuthStateModel>) {
    ctx.setState({
      token: null,
      username: null,
      email: null,
      refresh: null,
      roles: null,
      refreshTime: null,
      userId: null,
      companyStatus: null
    });
  }

  // Logout from microsoft service, removes their token
  logoutFromMicrosoftService(token: string): void {
    let decoded = jwt_decode(token);
    window.open(decoded.logoutLink ?? environment.clientUrl, '_self');
  }
}
