import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-refund-policies',
  templateUrl: './refund-policies.component.html',
  styleUrls: ['./refund-policies.component.scss']
})
export class RefundPoliciesComponent {
  constructor(
    private router: Router,

  ) { }

goToLogin(){
  this.router.navigate(['/login']);
}

}
