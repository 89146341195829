import { DomainDetailsModel } from './../models/domains/domain-details-model';
import { UserType } from './../models/users/user-model';
import { AreaModel, LocationModel } from './../models/other/location/location-model';
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { PaginationModel } from "../models/pagination";
import { OfferModel } from "../models";
import { OfferPostModel } from "../models/offers/offer-post-model";
import { QueryModel } from "../models/query/queryModel";
import { UserModel } from "../models/users/user-model";
import { CompanyModel } from '../models/companies/company-model';
import { SupplierModel } from '../models/supplier/supplier-model';
import { UserInvitationModel } from '../models/users/user-invitation-model';

@Injectable({
  providedIn: "root",
})
export class AdminService {
  private resource = `${environment.serverUrl}/administration`;

  constructor(private http: HttpClient) { }

  // Users

  private resourceUsers = `${environment.serverUrl}/users`;

  getUsersP(queryModel: QueryModel): Observable<PaginationModel<UserModel>> {
    const url = `${this.resourceUsers}/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }

  getUser(id: number): Observable<UserModel> {
    const url = `${this.resourceUsers}/${id}`;
    return this.http.get<UserModel>(url);
  }
  getLogData(id: number): Observable<any> {
    const url = `${environment.serverUrl}/log/offerData/` + id
    return this.http.get<any>(url);

  }
  createUser(user: UserModel): Observable<any> {
    return this.http.post<any>(this.resourceUsers, user);
  }

  sendAnnouncement(announcement: any): Observable<any> {
    const url = `${this.resource}/announcement`;
    return this.http.post<any>(url, announcement);
  }
  deleteUser(id: string) {
    const url = `${this.resourceUsers}/${id}`;
    return this.http.delete<any>(url);
  }

  existsUser(email: string) {
    const url = `${this.resourceUsers}/userexists/${email}`;
    return this.http.get<any>(url);
  }

  getAllUserInvitationsP(queryModel: QueryModel): Observable<PaginationModel<UserInvitationModel>> {
    const url = `${this.resource}/invited-users`;
    return this.http.post<any>(url, queryModel);
  }

  changeUserType(username: string, newType: string): Observable<any> {
    const user = {
      username,
      newType
    };

    return this.http.post<any>(`${this.resource}/change-user-type`, user);
  }

  editECard(eCard: any): Observable<any> {
    return this.http.post<any>(`${this.resource}/ecard`, eCard);
  }

  deleteUserInvitation(username: string) {
    return this.http.delete<any>(`${this.resource}/invited-user/${username}`);
  }

  insertUserInvitation(users: UserInvitationModel[]) {
    return this.http.post<any>(`${this.resource}/insert-into-invitation-table`, users);
  }

  deleteSelfInvited(): Observable<any> {
    return this.http.delete<any>(`${this.resource}/self-invited`);
  }

  getUserTypes(): Observable<UserType[]> {
    return this.http.get<UserType[]>(`${this.resource}/get-user-domains`);
  }
  getDomain(id: number): Observable<DomainDetailsModel> {
    return this.http.get<DomainDetailsModel>(`${this.resource}/get-user-domains/${id}`);
  }

  deleteUserType(id: string) {
    const url = `${this.resource}/user-domain/${id}`;
    return this.http.delete<any>(url);
  }

  // Suppliers

  private resourceSuppliers = `${environment.serverUrl}/administration/suppliers`;

  getSuppliersP(queryModel: QueryModel): Observable<PaginationModel<UserModel>> {
    const url = `${this.resourceSuppliers}/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }

  getSupplier(id: number): Observable<UserModel> {
    const url = `${this.resourceSuppliers}/${id}`;
    return this.http.get<UserModel>(url);
  }

  createSupplier(supplier: UserModel): Observable<any> {
    return this.http.post<any>(this.resourceSuppliers, supplier);
  }

  deleteSupplier(id: string) {
    const url = `${this.resourceSuppliers}/${id}`;
    return this.http.delete<any>(url);
  }

  getSuppliersPendingP(): Observable<CompanyModel> {
    const url = `${this.resource}/suppliers-pending`;
    return this.http.get<any>(url);
  }

  approveOrRejectSuppliers(
    companyId: string,
    approved: boolean
  ): Observable<CompanyModel> {
    let ret = {
      companyId: companyId,
      approved: approved,
    };
    const url = `${this.resource}/supplier-approve-reject`;
    return this.http.post<any>(url, ret);
  }

  //Location

  getAllDefaultLocations(): Observable<LocationModel[]> {
    const url = `${this.resource}/locations`;
    return this.http.get<any>(url);
  }

  getAllDefaultAreas(): Observable<AreaModel[]> {
    const url = `${this.resource}/areas`;
    return this.http.get<any>(url);
  }

  getDefaultAreaById(areaId: number): Observable<AreaModel> {
    const url = `${this.resource}/areas/${areaId}`;
    return this.http.get<any>(url);
  }

  createDefaultArea(area: AreaModel): Observable<AreaModel> {
    const url = `${this.resource}/areas`;
    return this.http.post<AreaModel>(url, area);
  }

  updateDefaultArea(area: AreaModel): Observable<AreaModel> {
    const url = `${this.resource}/areas/${area.id}`;
    return this.http.put<any>(url, area);
  }

  deleteDefaultArea(areaId: number): Observable<any> {
    const url = `${this.resource}/areas/${areaId}`;
    return this.http.delete<any>(url);
  }

  // Domain
  createUserDomain(domain: DomainDetailsModel) {
    return this.http.post<any>(`${this.resource}/add-user-domain`, domain);
  }

  updatedUserDomain(domain: DomainDetailsModel) {
    return this.http.post<any>(`${this.resource}/update-user-domain`, domain);
  }
  //
  getLastDataSyncUserCount(date: string): Observable<number> {
    const url = `${this.resource}/lastDataSynchronization/${date}`;
    return this.http.get<number>(url);
  }

  // Inspect
  checkUserRoles(username: string): Observable<any> {
    const url = `${this.resource}/get-user-roles/${username}`;
    return this.http.get<any>(url);
  }

  // Push notification
  pushNotification(notification: { Title: string, Message: string }): Observable<any> {
    notification.Message = this.encodeString(notification.Message);
    notification.Title = this.encodeString(notification.Title);
    return this.http.post<any>(`${this.resource}/offers-send-push-notifications`, notification);
  }

  generateReport(type): Observable<any[]> {
    return this.http.get<any[]>(`${this.resource}/reports/${type}`);
  }

  generateSupplierReport(startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(`${this.resource}/reports/supplier-report/${startDate}/${endDate}`);
  }

  private encodeString(baseString: string): string {
    return btoa(unescape(encodeURIComponent(baseString)));
  }

  getCountersForLogin(): Observable<any> {
    return this.http.get(`${this.resource}/logincounter`);
  }

}
