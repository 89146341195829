<div class="footer-container">
    <div class="footer">
        <div class="content">
            <!-- <div class="copyright">Copyright © 2020 Abu Dhabi National Oil Company</div> -->
            <!-- <div class="terms" (click)="toggleModalTerms()">Terms and Conditions</div> -->
            <a [href]="url" target="_blank">Terms and Conditions</a>
        </div>
    </div>
</div>

<app-terms-modal *ngIf="modalTerms" (closeTermsModalE)="toggleModalTerms()"></app-terms-modal>