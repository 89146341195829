import { Subscription } from 'rxjs';
import { AdnocUsersService } from './../../shared/services/adnoc-users.service';
import { ChangeSearch, DeleteHistory, ResetPage } from './../../state/page-history-state-model';
import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState, Logout, PageHistoryState } from 'src/app/state';
import { LoginService } from 'src/app/core/services';
import { LocalStorage } from 'src/app/core/security/local-storage';
import { Auth, AuthSecurity } from 'src/app/core/security/auth';
import { FiltersService } from 'src/app/shared/services/filters.service';

import { MyMazayaPlusService } from 'src/app/features/my-mazaya-plus/services';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MemberGenderModel } from 'src/app/shared/models/mazaya-plus/card-details-model';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})

export class NavigationComponent implements OnInit, OnDestroy {

  @ViewChild('navSub', { read: ElementRef }) public navSubSmallScreen;

  // Search
  searchTextPlaceholder: string = "";
  searchText: string = "";
  genderForm: FormGroup;
  // Modals
  notificationModal: boolean;
  dropDownUserModal: boolean;
  dropDownNavModal: boolean;
  selectedDropDown: string;
  mobileNumberError: boolean = false;
  // Profile image
  profilePicture: string = null;
  clientsPhotoChanged: Subscription;

  // Responsive navigation
  timerNavSmall: any;
  activeNav: string;
  isMazayaEligible: boolean = false;
  // isMazayaAdmin: boolean = false;
  isMazayaAdmin: string ;
  membercardcount: boolean = false;
  powermembercardcount:boolean=false;
  exploremembercardcount:boolean=false;
  // signalR
  assignedOffersCount: number = 0;
  blockedOffersCount: number = 0;
  // phoneNotSpecified: boolean = false;
  // genderNotSpecifiedUser: boolean = false;

  phoneNumber: string = '';
  gender: string = '';

  isModalOpen: boolean = false;
  role: string;
  private routerSubscription: Subscription;
  constructor(
    private router: Router,
    private store$: Store,
    private adnocUserService: AdnocUsersService,
    private loginService: LoginService,
    private _filterService: FiltersService,
    private _mazayaPlusService: MyMazayaPlusService,
    private fb: FormBuilder,
  
  ) {
    this.genderForm = this.fb.group({
      membersList: this.fb.array([])
    });
    this.router.events.subscribe((event: Event) => {
      this.changeActiveNav(this.router.url.split("/")[1]);
    });
    
    
  }

  ngOnInit() {
    console.log("isModalOpen",this.isModalOpen)
    this.loadAuth();
    this.initSearch();
    this.initUserProfileImage();
    this.subscribeFor_usersPhotoChanged();
    this.checkMazayaPlus();
    console.log('Local Storage at ngOnInit:', localStorage.getItem('isMazayaAdmin'));

    // this.getMembersWithoutGender(true)
 
    //this.checkForLoginPage(this.router.url);
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
   
        this.checkForLoginPage(event.urlAfterRedirects);
      }
    });
  }

  loadAuth() {
    this.store$.select(AuthState.roles).subscribe((roles) => {
      this.role = roles[0];
    });
  }

  subscribeFor_usersPhotoChanged() {
    this.clientsPhotoChanged = this.adnocUserService.clientsPhotoChanged.subscribe(() => {
      this.initUserProfileImage();
    });
  }
  checkForLoginPage(currentUrl: string) {

    if (currentUrl === '/login' ) {
      this.isModalOpen = false;
    }
  }
  initUserProfileImage() {
    if (!this.store$.selectSnapshot(AuthState.userId)) {
      return;
    }
    this.adnocUserService.getAdnocUsersProfilePicture().subscribe((res) => {
      if (res) {
        this.profilePicture = res.toLocaleUpperCase(); // to upper and to string
      } else {
        this.profilePicture = null;
      }
    });
  }

  userProfileImageState($event) {
    // if ($event.reason == "loading-failed") {
    //   setTimeout(() => {
    //     this.profilePicture = "111-111-111-111";
    //     this.initUserProfileImage();
    //   }, 500);
    // }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    this.clientsPhotoChanged.unsubscribe();
  }

  initSearch() {
    this.store$.select(PageHistoryState).subscribe((res) => {
      this.searchTextPlaceholder = res.searchPlaceholder;
      this.searchText = res.query.filter.keyword;
    });
  }

  changeActiveNav(activeNav) {

    this.activeNav = activeNav;
  }

  routeToAllSuggestion() {
    this.router.navigateByUrl('suggestions/all')
  }
  search() {
    this.store$.dispatch(new ChangeSearch(this.searchText));
  }

  searchReset() {
    this.searchText = "";
    this.search();
  }

  logOut() {
    this.loginService.logout().subscribe(() => {
      localStorage.clear();
      sessionStorage.clear();
      this.dropDownUserModal = !this.dropDownUserModal;
      this.store$.dispatch(new DeleteHistory());
      this.store$.dispatch(new ResetPage());
      this.store$.dispatch(new Logout());
      this.genderForm.reset();
      this.membersList.clear();
    })

  }

  reset() {
    this.store$.dispatch(new ResetPage());
  }

  // Navigations (responsive)
  moveToRightNavSub(): void {
    this.timerNavSmall = setInterval(() => {
      this.navSubSmallScreen.nativeElement.scrollLeft += 15;
    }, 50);
  }

  moveToLeftNavSub(): void {
    this.timerNavSmall = setInterval(() => {
      this.navSubSmallScreen.nativeElement.scrollLeft -= 15;
    }, 50);
  }

  stopMoveNavSub(): void {
    clearInterval(this.timerNavSmall);
  }
  checkMazayaPlus() {
    this.store$.select(AuthState.token).subscribe((token) => {
      if (token) {
        this._filterService.getUserInfo().subscribe((res) => {
          this.isMazayaAdmin = res.mazayauserdetails.isAdmin;
          this.isMazayaEligible = res.isMazayaEligible;
          if (this.isMazayaAdmin ) {

            this.getMembersWithoutGender(true)
          } else {
       
            this.getMembersWithoutGender(false)
          }
   
          if (this.router.url === '/login') {
            this.isModalOpen = false; 
          } else {
            this.isModalOpen = res.phoneNotSpecified || res.genderNotSpecifiedUser;
          }

          if (res.mazayauserdetails.membercardcount != 0) {
            this.membercardcount = true;
          }

          if (res.powerexploredetails.powermembercardcount !=0) {
         
            this.powermembercardcount = true;
          }
  
          if (res.powerexploredetails.exploremembercardcount !=0) {
            this.exploremembercardcount = true;
          } 
            
        });
      }
    });
  }
  
  getMembersWithoutGender(isAdmin: boolean) {
    this.store$.select(AuthState.token).subscribe((token) => {
      if (token) {
      

        this._mazayaPlusService.getMembersWithoutGender(isAdmin).subscribe((res) => {
          if (res.length !== 0) {
           
            res.forEach((element: MemberGenderModel,index) => {
              if(index==0){
                this.genderForm.reset();
                this.membersList.clear();
              }
              let phoneNumber = element.phoneNumber.startsWith('971')
              ? element.phoneNumber.substring(3) 
              : element.phoneNumber;

              console.log("!!!!!!!!!!!!!!!",element)
              this.membersList.push(this.createMemberFormGroup({ 
                ...element, 
                phoneNumber 
              }));
              console.log("!!!!!!!!!!!!!!!",element)
              // this.membersList.push(this.createMemberFormGroup(element));
            });
          } else {
           
            // this.isModalOpen = false;  
          }
        });
      }
    });
  }
  

  get membersList() {
    return this.genderForm.get('membersList') as FormArray;
  }
  createMemberFormGroup(item: MemberGenderModel) {
    return this.fb.group({
      name: [item.name],
      ecarddetailsId: [item.ecarddetailsId],
      gender: [item.gender, Validators.required],
      phoneNumber:[item.phoneNumber, [Validators.required]],
     
      isCardUser:[item.isCardUser]
    });
  }
  // updateMembersGender() {


  //   if (this.genderForm.valid) {
  //     console.log(this.genderForm.value);
  //     const list = this.genderForm.value
  //     this._mazayaPlusService.updateGender(list.membersList)
  //       .subscribe((res) => {
  //         this.modalGenderUpdate=false;
  //       })
  //   } else {
  //     this.genderForm.markAllAsTouched(); // Mark all fields as touched to trigger validation messages
  //   }


  updateMembersGender() {
    if (this.genderForm.valid) {
      const transformedList = this.membersList.value.map((member: any) => ({
        ...member,
        phoneNumber: '971' + member.phoneNumber 
      }));
  
      console.log(transformedList); 
  
      this._mazayaPlusService.updateGender(transformedList).subscribe(
        (res) => {
          this.isModalOpen = false;
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      this.genderForm.markAllAsTouched();
    }
  }
  
 
}
