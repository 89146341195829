export const environment = {
  production: true,

  serverUrl: 'https://api.mazayaoffers.ae/api',//production

  socketUrl: 'https://api.mazayaoffers.ae',
  clientUrl: 'https://api.mazayaoffers.ae',
  useSSO: false,
  instrumentationKey: null,
  defaultLanguage: 'en-US',

  payfortactlink: 'https://checkout.payfort.com/FortAPI/paymentPage',
  payfortcommand: 'PURCHASE',
  payfortaccesscode: 'dclR1r9SDf4Fe0kdwlUv',
  payfortmerchantidentifier: 'yDVnFjkT',
  shaRequest: "81mgwrg8AUZh/plPBEp1mT$(",


  
  // serverUrl: 'https://mazaya-api.gewaninfotech.com/api', //staging
  // socketUrl: 'https://mazaya-api.gewaninfotech.com',
  // clientUrl: 'https://mazaya-api.gewaninfotech.com',
  // useSSO: false,
  // instrumentationKey: null,
  // defaultLanguage: 'en-US',

  // payfortactlink: 'https://sbcheckout.payfort.com/FortAPI/paymentPage',
  // payfortcommand: 'PURCHASE',
  // payfortaccesscode: 'pXOgNQvchAF3hPktmdNN',
  // payfortmerchantidentifier: 'uBtRIcqH',
  // shaRequest: "cnsduifgwegfy",


};

